<template>
	<b-modal id="deposit-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				{{ $t('blxDashboard.depositLabel') }}
			</h5> 
			<div></div>

    </template>


		<div  > 
				<div class="deposit-container" >
	    			<div class="text-center"> <span class="bold"> {{ $t('blxDashboard.balance') }}: </span> {{ formatAmount(init.fund.balance) }}  </div>
	    			 <div>
				   			<qrcode v-bind:value="init.address.addressValue" v-bind:options="{ width: 200 }" class="qrCanvas"></qrcode>
				   		</div>

				   		<p>{{ $t('blxDashboard.depositMsg') }}:</p>

				   		<div class="input-group">
					      <input type="text" class="form-control" id="addressValue" v-model="init.address.addressValue" readonly>
					      <div class="input-group-btn">
					        <button class="btn btn-main" type="button" id="tooltip-target-1" v-on:click="copy">{{ $t('dashboard.copy') }}</button>
									 <b-tooltip target="tooltip-target-1" triggers="click">
									    {{ $t('blxDashboard.copied') }}
									  </b-tooltip>
					      </div>
					    </div>
				</div> 
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
import Vue from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode'
 
Vue.component(VueQrcode.name, VueQrcode);

export default {
  name: 'Deposit',
  data: function() {
      return  {
      	
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    
   	},
   	methods: {
   		close: function(){
   			this.$bvModal.hide('deposit-modal'); 
   		},
   		 formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

      	copy: function(){
   			var copy = document.getElementById("addressValue");

        copy.select();
        copy.setSelectionRange(0, 99999);

        document.execCommand("copy"); 
   		},
   	},
   	beforeMount(){
 		},

}
</script>
 
<style scoped lang="scss">
	
	.deposit-container {
		    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	}

	
</style>
